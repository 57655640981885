import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

/* eslint-disable no-else-return */
const getTokenLogoURL = memoize(
  (token?: Token) => {
    // console.log('aspokdspo: ' + token)
    // console.log(token);
    // console.log(token.symbol);
    if (token.symbol === "MOCHI") {
      return 'https://mochidefi.io/logoToken128.png'
    }
    else if (token && mapping[token.chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
        token.address,
      )}/logo.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)


// const getTokenLogoURL = memoize(
//   (token?: Token) => {
//       return 'https://mochidefi.io/logoToken128.png'
//     // return null
//   },
//   (t) => `${t.chainId}#${t.address}`,
// )


/* eslint-enable no-else-return */

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address === "0x92072F045D0904e9a0cDfD48519f54c83Bf41e82") {
      return 'https://mochidefi.io/logoToken128.png'
    }
    
    if (address && chainId && mapping[chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[chainId]}/assets/${getAddress(address)}/logo.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
